import {
  AnyApiFactory,
  analyticsApiRef,
  configApiRef,
  createApiFactory,
  errorApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { exploreToolsConfigRef } from '@backstage-community/plugin-explore-react';
import {
  GraphQLEndpoints,
  graphQlBrowseApiRef,
} from '@backstage-community/plugin-graphiql';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { QuantumMetric } from '@quantum-metric/plugin-analytics-module-qm';
import { TechRadarClient } from './lib/TechRadarClient';
import { tools } from './tools';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: exploreToolsConfigRef,
    deps: {},
    factory: () => ({
      async getTools() {
        return tools;
      },
    }),
  }),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: { errorApi: errorApiRef },
    factory: () =>
      GraphQLEndpoints.from([
        GraphQLEndpoints.create({
          id: 'gitlab',
          title: 'GitLab',
          url: 'https://gitlab.quantummetric.com/api/graphql',
          headers: {
            Authorization: 'Bearer glpat-by6E9PXxuerFguGXBESd',
          },
        }),
        {
          id: 'qm-api',
          title: 'QM API',
          fetcher: async (params: any, options: any = {}) => {
            let retried = false;

            const url = 'https://api.quantummetric.com/query';

            // the following is hardcoded for testing purposes and
            // will eventually be extracted so that we are not providing
            // credentials within the codebase
            const tokenRequest = {
              grant_type: 'client_credentials',
              scope: 'sys:*',
              client_id: 'backstage-service-account-55a384c32436',
              client_secret: '138d1ad6bfad76710fab45e0233eddd2',
            };

            const doRequest = async (): Promise<any> => {
              let iamToken: string =
                window.localStorage.getItem('iamToken') || '';
              if (!iamToken) {
                const res = await fetch(
                  'https://iam-dev.quantummetric.com/oauth2/token',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer`,
                      ...options.headers,
                    },
                    body: JSON.stringify(tokenRequest),
                  },
                );

                if (!res.ok) {
                  throw new Error(
                    `Request failed with status ${res.status} ${res.statusText}`,
                  );
                }
                const data = await res.json();
                iamToken = data.access_token;

                if (!iamToken) {
                  window.localStorage.setItem('iamToken', iamToken);
                }
              }

              const d = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${iamToken}`,
                  ...options.headers,
                },
                body: JSON.stringify(params),
              });

              if (!d.ok || retried) {
                return d;
              }
              retried = true;
              return doRequest();
            };

            return await doRequest();
          },
        },
      ]),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      QuantumMetric.fromConfig(configApi, {
        identityApi,
      }),
  }),
  //   .registerApi({
  //     api: techInsightsApiRef,
  //     deps: {},
  //     factory: () =>
  //       ({
  //         getCheckResultRenderers: (_: string[]) => checkResultRenderers,
  //         isCheckResultFailed: (_: CheckResult) => true,
  //         getAllChecks: async () => [],
  //         runChecks: async (_: CompoundEntityRef, __?: string[]) =>
  //           runChecksResponse,
  //         runBulkChecks: async (_: CompoundEntityRef[], __?: Check[]) =>
  //           bulkCheckResponse,
  //         getFacts: async (_: CompoundEntityRef, __: string[]) => '' as any,
  //         getFactSchemas: async () => [],
  //       } as TechInsightsApi),
  //   })
  //   createApiFactory({
  //     api: techInsightsApiRef,
  //     deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
  //     factory: ({ discoveryApi, identityApi }) =>
  //       new TechInsightsClient({
  //         discoveryApi,
  //         identityApi,
  //         renderers: [
  //           jsonRulesEngineCheckResultRenderer, // default json-rules-engine renderer
  //         ],
  //       }),
  //   }),
];
