/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#93c47d' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
quadrants.push({ id: 'frameworks', name: 'Frameworks' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'process', name: 'Process' });

type ring = {
  hold?: string[];
  assess?: string[];
  trial?: string[];
  adopt?: string[];
};

type matrix = {
  frameworks: ring;
  languages: ring;
  process: ring;
  infrastructure: ring;
};

const state: matrix = {
  frameworks: {
    hold: ['ImmutableJS', 'Laminar', 'Redux'],
    adopt: ['React', 'WebPack'],
  },
  languages: {
    hold: ['Rust'],
    trial: ['Python'],
    adopt: ['Go', 'TypeScript'],
  },
  process: {
    assess: ['ADR'],
    adopt: ['RFC', 'Code Review'],
  },
  infrastructure: {
    adopt: ['Kubernetes', 'GitLab CI'],
  },
};
const lastUpdated = new Date('2023-01-18');

const entries = new Array<RadarEntry>();

const normalize = (s: string) => {
  return s.toLowerCase().replace(' ', '-');
};

Object.entries(state).forEach(([quadrant, r]) => {
  Object.entries(r).forEach(([ringId, items]) => {
    items.forEach(item => {
      entries.push({
        url: '#',
        key: normalize(item),
        id: normalize(item),
        title: item,
        quadrant,
        timeline: [
          {
            ringId,
            date: lastUpdated,
          },
        ],
      });
    });
  });
});

export const data: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings,
};

export class TechRadarClient implements TechRadarApi {
  async load() {
    return data;
  }
}
